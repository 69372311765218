import { useState, useRef } from "react";
import { ethers } from "ethers";
import ErrorMessage from "./ErrorMessage";

const signMessage = async ({ setError, message }) => {
  try {
    console.log({ message });
    if (!window.ethereum)
      throw new Error("No crypto wallet found. Please install it.");

    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);
    const address = await signer.getAddress();

    return {
      message,
      signature,
      address
    };
  } catch (err) {
    setError(err.message);
  }
};

export default function SignMessage() {
  const resultBox = useRef();
  const [signatures, setSignatures] = useState([]);
  const [error, setError] = useState();
  const [nonceData, setNonceData] = useState();

  const handleSign = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    setError();
    const sig = await signMessage({
      setError,
      message: data.get("message")
    });
    if (sig) {
      setSignatures([...signatures, sig]);
    }
  };

  const getNonce = async (e) => {
    // e.preventDefault();
    if (!window.ethereum)
      throw new Error("No crypto wallet found. Please install it.");

    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    console.log(address)
    fetch('https://test.thangbao.com/api/v1/login/nonce?address='+address).then(res => res.json()).then(res => {
      console.log(res)
      setNonceData(res.data)
    })
  };


  const register = async (e) => {

    const sig = await signMessage({
      setError,
      message: nonceData
    });

    console.log(sig.signature)

    fetch("https://test.thangbao.com/api/v1/login/login_register", {
      method: "POST",
      headers: new Headers({
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=UTF-8",
      }),
      body: JSON.stringify({
        address: sig.address,
        sign: sig.signature,
      }),
    }).then((res) => {
          return res.json().then((response) => {
            console.log(response);
            if (response.code  == 200) {
              alert("token:"+response.data)
            }else{
              alert("fail:"+response.message)
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });

  };


  return (
    <form className="m-4" onSubmit={handleSign}>
      <div className="credit-card w-full shadow-lg mx-auto rounded-xl bg-white">
        <main className="mt-4 p-4">
          <h1 className="text-xl font-semibold text-gray-700 text-center">
            Sign messages
          </h1>
          <div className="">
            <div className="my-3">
              <textarea
                required
                type="text"
                name="message"
                className="textarea w-full h-24 textarea-bordered focus:ring focus:outline-none"
                placeholder="Message"
                value={nonceData}
              />
            </div>
          </div>
        </main>
        <footer className="p-4">
          <button
            type="submit"
            className="btn btn-primary submit-button focus:ring focus:outline-none w-full"
          >
            Sign message（本地测试）
          </button>
          <br/>
          <br/>
          <button
              type="button"
              onClick={getNonce}
              className="btn btn-primary submit-button focus:ring focus:outline-none w-full"
          >
            Get Nonce(从远程服务端获取nonce)（第一步）
          </button>

          <br/>
          <br/>
          <button
              type="button"
              onClick={register}
              className="btn btn-primary submit-button focus:ring focus:outline-none w-full"
          >
            签名nonce 注册账户 （第二步）
          </button>

          <ErrorMessage message={error} />
        </footer>
        {signatures.map((sig, idx) => {
          return (
            <div className="p-2" key={sig}>
              <div className="my-3">
                <p>
                  Message {idx + 1}: {sig.message}
                </p>
                <p>Signer: {sig.address}</p>
                <textarea
                  type="text"
                  readOnly
                  ref={resultBox}
                  className="textarea w-full h-24 textarea-bordered focus:ring focus:outline-none"
                  placeholder="Generated signature"
                  value={sig.signature}
                />
              </div>
            </div>
          );
        })}
      </div>
    </form>
  );
}
